@import "../scss/icons.scss";
@import "../scss/config/saas/bootstrap.scss";
@import "../scss/config/saas/app.scss";
@import "../scss/config/saas/custom.scss"; 
@import"./shepherd.css";
@import "./mermaid.min.css";

//RTL 
// @import "../scss/rtl/components-rtl";
// @import "../scss/rtl/layouts-rtl";
// @import "../scss/rtl/plugins-rtl";
// @import "../scss/rtl/pages-rtl";

//Rigthsidebar offcanvas
.offcanvas-header-dark {
  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }
}

body {
  * {
    outline: none;
  }
}
.noUi-horizontal .noUi-handle {
  height: 16px !important;
  width: 16px !important;
  border-radius: 50% !important;
  right: -10px !important;
  top: -7px !important;
}
.noUi-connect,
.noUi-horizontal {
  height: 2px !important;
  background: #13c56b !important;
}
.noUi-handle {
  background: #13c56b !important;
  border: 2px solid var(--vz-card-bg) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.noUi-target {
  background: #fafafa !important;
  border-radius: 4px !important;
  border: 1px solid #d3d3d3 !important;
  background-color: var(--vz-light) !important;
  border-color: var(--vz-light) !important;
  box-shadow: none !important;
  height: 4px !important;
}
.dropzone .dz-message {
  text-align: center;
}

[dir=rtl] footer.footer .text-sm-end {
  text-align: left !important;
}

// //Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}
.fc-event {
  border: none !important;
}

@include media-breakpoint-down(sm) {
  .fc-toolbar {
    display: block !important;
    text-align: center;
    .fc-left,
    .fc-right,
    .fc-center {
      float: none;
      display: block;
      clear: both;
      margin: 10px 0;
    }
  }
  .fc {
    .fc-toolbar {
      > * {
        > * {
          float: none;
        }
      }
    }
  }
  .fc-today-button {
    display: none;
  }
}
.flatpickr-calendar.open {
  z-index: 1090 !important;
}
.upcoming-scheduled .flatpickr-input[readonly] {
  display: none;
}
.ql-container.ql-snow {
  height: 250px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #6691e7 !important;
}

// .Toastify__toast{
//   min-height: 43px !important;
//   max-height: 43px !important;
// }

.react-tel-input .form-control{
  width:100% !important;
}
.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 43px;
  height: 100%;
  padding: 0 4px 0 0px !important;
  border-radius: 3px 0 0 3px;
  display: flex !important;
  justify-content: center !important;
}
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  left: 10px !important;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Adjust the spacing between buttons */
  margin-top: 20px; /* Add margin to the top for spacing from other content */
}

.custom-button {

  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.page-class {
  display: flex;
  align-items: center;
  padding:5px;
  color: grey;
}

button:hover {
  background-color: #45a049;
}


.opt-container {
  text-align: center;
  }
  .opt-container input {
    margin: 0 4px;
    flex: 0 0 35px;
    padding: 5px;
    outline: none;
  }
  .opt-container>div {
    justify-content: center;
}

@each $color, $value in $theme-colors {
  .simplebar-track-#{$color} {
    .simplebar-scrollbar:before{
      background: $value !important; 
    }
  }   
}

// chat
.chat-conversation .chat-list .message-box-drop{
  .nav-btn{
      font-size: 18px;
      padding: 4px;
      color: $gray-600;
  }
}

.chatwidgets {
  height: 250px !important;
}

[data-layout="horizontal"] .chat-conversation{
  &.chatwidgets {
    height: 250px !important;
  }
}

// Authentication pages 
#tsparticles{
  canvas{
    position: absolute !important;
  }
}

// landing page
.process-card {
    .process-arrow-img {
        left: auto;
        right: 75%;
        transform: scaleX(-1);
    }
}
.icon-effect {
    &::before {
        left: auto;
        right: 0;
    }
}

.marketplace-swiper {
  .swiper-button-next, .swiper-button-prev {
    top: 22px;
  }
}

.alert-msg-show {
  right: 24px;
  top: 40px;
  z-index: 1005;
}

// Emoji Picker

.pickerEmoji{
  position: absolute;
  bottom: -50px;
  left: 10%;
  transform: translateX(-50%);
}

// Toast
.Toastify {
  .Toastify__toast{
    min-height: 43px;
  }
  .Toastify__close-button {
    top: 6px;
  }
  .Toastify__close-button--light {
    color: $light;
  }
}

// Tranfer List

.rdl-filter, .rdl-control{
  background-color: $input-bg;
  border: 1px solid $input-border-color !important;
  color: $input-color !important;
}

.rdl-move{
  background: var(--#{$prefix}light) !important;
  border: 1px solid var(--#{$prefix}light) !important;
  color: var(--#{$prefix}body-color) !important;
}

.react-dual-listbox {
  input:disabled, select:disabled{
    background-color: $input-disabled-bg !important;
    border: 1px solid $input-border-color !important;
  }
}

.css-26l3qy-menu{
  background-color: var(--#{$prefix}gray-200) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

.css-1n7v3ny-option{
  background-color: var(--#{$prefix}gray-300) !important;
  border-color: var(--#{$prefix}border-color) !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px var(--#{$prefix}input-bg) inset !important;
    background-color: var(--#{$prefix}input-bg) !important;
    color: var(--#{$prefix}body-color) !important;
    -webkit-text-fill-color: var(--#{$prefix}body-color) !important;
}
.cis-width-120 {
  min-width: 120px;
}
.mw-400 {
  max-width: 400px;
}
[dir="rtl"] .btn-close {
  margin-left: 0;
  margin-right: auto;
}
[dir='rtl'] input[type=number]:placeholder {
  text-align:right;
}
[dir='rtl'] input[type=number]::placeholder {
  text-align:right;
}
.otp-error {
  text-align: center;
}


// 05March2024
[dir=rtl] .auth-page-content .react-tel-input .form-control {
  padding-left: 48px;
  padding-right: 40px;
}

[dir=rtl] .auth-page-content .dropdown-item {
  text-align: right;
}
[dir=rtl] .auth-page-content .dropdown-item>img {
  float: right;
  margin-right: 0 !important;
  margin-left:5px;
}
[dir=rtl] .auth-page-content .react-tel-input .country-list .flag {
  margin-right: 0;
  margin-left: 7px;
}


.cis-card_wrap {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 30px;
  /* border: 1px solid #ffceb4; */
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

.cis-card_wrap h3 {
  font-size: 18px;
  margin: 0 0 15px;
}

.cis-card_wrap .card {
  background: #fafafa;
}

.cis-card_wrap.colored_shade_1 .row >div:nth-child(2)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_1 .row >div:nth-child(3)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_1 .row >div:nth-child(4)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_2 .row >div:nth-child(2)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_2 .row .col-xl-4:nth-child(3)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_2 .row .col-xl-4:nth-child(4)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_3 .row>div:nth-child(2)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_3 .row>div:nth-child(3)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_3 .row>div:nth-child(4)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_4 .row>div:nth-child(2)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_4 .row>div:nth-child(3)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_4 .row>div:nth-child(4)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_5 .row >div:nth-child(2)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_5 .row .col-xl-4:nth-child(3)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}
.cis-card_wrap.colored_shade_5 .row .col-xl-4:nth-child(4)>.card {
  background-color: #0beef9;
  background-image: linear-gradient(315deg, #0beef9 0%, #48a9fe 74%);
}  


.cis-card_wrap .card {
  position: relative;
  overflow: hidden;
}
.cis-card_wrap .card-icon img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
.cis-card_wrap .card-icon {
  width: 132px;
  height: 132px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  position: absolute;
  bottom: -60px;
  
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
  
[dir=ltr] .cis-card_wrap .card-icon {
  padding-bottom: 40px;
  padding-right: 30px;
  right: -40px;
}
[dir=rtl] .cis-card_wrap .card-icon {
  padding-bottom: 40px;
  padding-left: 30px;
  left: -40px;
}

.cis-card_wrap .card {
  overflow: hidden;
}
[dir=rtl] .navbar-header .user-name-text {
  margin-left: 0 !important;
  margin-right: 0.5em;
}
.cis-card_wrap .card .text-truncate {
  color: #000000 !important; 
  opacity: 0.8;
}

.refer-voters-btn-wrap {
  display: flex;
  gap: 15px;
}
.refer-voters-btn-wrap i {
  line-height: 1;
  vertical-align: middle;
}
.refer-voters-row {

}
@media(max-width: 1024px) {
  .refer-voters-row .col {
      flex: 0 0 25%;
      max-width: 25%;
      width: 25%;
  }

  .refer-voters-row .cis-btn-col.col {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
  }

  .refer-voters-btn-wrap {
      justify-content: center;
      margin-top: 10px;
  }

  .refer-voters-row .cis-btn-col.col label {
    display: none;
  }
  
}
@media(max-width: 767px) {
  .refer-voters-row .col {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
    margin-bottom: 20px;
}
}
@media(max-width: 575px) {
  .refer-voters-row .col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
}
}
// [dir=rtl] .auth-page-content .react-tel-input .form-control {
//   padding-left: 10px;
//   padding-right: 40px;
// }
[dir=rtl] .auth-page-content .react-tel-input .flag-dropdown  {
  left: 0;
  right: auto;
}

.voters-management-search-row .col {
  flex: 0 0 calc(25% - 38px);
  max-width: calc(25% - 38px);
  width: calc(25% - 38px);
}
.voters-management-search-row .col.vmsr-btn {
  flex: 0 0 150px;
  max-width: 150px;
  width: 150px;
}
.voters-management-search-row .col.vmsr-btn {
  padding-top: 26px;
}

.voters-management-search-row .col.vmsr-btn btn i {
  line-height: 1;
}
.voters-management-search-row .col.vmsr-btn .btn {
  width: 100%;
}

// navigation in res 
[dir=rtl] .navbar-menu .navbar-nav .nav-sm,
[dir=rtl] .menu .navbar-menu .navbar-nav {
    padding-right: 0;
}


[dir=rtl] .navbar-menu .navbar-nav .nav-sm .nav-link:before {
    left: auto;
    right: 5px;
}

.all-voter-list-row .cis-manage-demo-btn {
  display: inline-flex;
  gap: 15px;
  width: 100%;
}

.all-voter-list-row  .duel-btn-wrap {
  display: inline-flex;
  gap: 15px;
  width: 100%;
}
.all-voter-list-row .cis-manage-demo-btn .btn,
.all-voter-list-row  .duel-btn-wrap .btn {
  margin: 0 !important;
  white-space: nowrap;
}
.refered-voters-list-row .cis-manage-demo-btn {
  display: inline-flex;
  gap: 15px;
}
.cis-card_wrap .card .text-truncate {
  min-height: 40px;
  white-space: normal;
}
.cis-card_wrap .card .mt-4 {
  margin-top: 5px !important;
}
.cis-card_wrap .card .mb-4 {
  margin-bottom: 10px !important;
}
.area-wise-report-row .cis-manage-demo-btn {
  display: inline-flex;
  gap: 15px;
}
// navigation in res 
@media(max-width:1450px) {
  .all-voter-list-row .col {
    flex: 0 0 20%;
    max-width: 20%;
    margin-bottom: 20px;
  }
  .all-voter-list-row .avlr-action-btns.col {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0px;
  }
  .all-voter-list-row .avlr-action-btns label {
    display: none;
  }
  .all-voter-list-row .cis-manage-demo-btn {
  justify-content: center;
}
}
@media(max-width:991px) {
  .voters-management-search-row .col {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    width: 33.33%;
    margin-bottom: 20px;
  }
  .all-voter-list-row .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .all-voter-list-row .col:nth-child(1), .all-voter-list-row .col:nth-child(2), .all-voter-list-row .col:nth-child(3) {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media(max-width:767px) {
  .voters-management-search-row .col {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
  .voters-management-search-row .col {
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
  .voters-management-search-row .col.vmsr-btn {
    padding-top: 0;
  }


  .refered-voters-list-row .cis-manage-demo-btn .btn {
  white-space: normal;
}
.election-day-report-row [data-column-id]:not(:first-child) {
  padding-left: 0px;
  min-width: 70px;
}
.election-day-report-row [data-column-id]:last-child {
  min-width: 110px;
}

.election-day-report-row [data-column-id] [data-column-id] {
    min-width: auto;
}
.area-wise-report-row .col {
  flex: 0 0 100%;
  max-width: 100%;
}
.area-wise-report-row .col .mw-400 { 
  max-width: 100%;
}
}
@media(max-width:575px) {
  .voters-management-search-row .col {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
  .all-voter-list-row .col, .all-voter-list-row .col:nth-child(1), .all-voter-list-row .col:nth-child(2), .all-voter-list-row .col:nth-child(3) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .all-voter-list-row .cis-manage-demo-btn {
    flex-wrap: wrap;
  }    
  
  .refered-voters-list-row .col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .refered-voters-list-row .cis-manage-demo-btn {
      margin-bottom: 15px;
  }
  .area-wise-report-row .cis-manage-demo-btn { 
    margin-bottom: 15px;
  }

  .refered-voters-list-row .col .mw-400 {
      max-width: 100%;
  }
}

.highlighted {
  font-weight: bold;
}

.flag-dropdown .country-list {
  left: 0;
  right: auto;
  overflow: auto;
}

.flag-dropdown .country-list .country.highlight {
  direction: ltr;
  text-align: left;
}

.flag-dropdown .country-list .country.highlight .flag {
  margin-left: 0;
  margin-right: 5px;
}

.make-admin-booth {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
}

.make-admin-booth input {
  width: 18px;
  height: 18px;
  padding: 0;
}




.cis-card_wrap.colored_shade_3.card_strip .row > div > .card .card-body {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 7px 15px 7px 50px;
}

.cis-card_wrap.colored_shade_3.card_strip .row > div > .card  .text-truncate {
  min-height: auto;
}

.cis-card_wrap.colored_shade_3.card_strip .ff-secondary.mb-4 {
  margin-bottom: 0 !important;
}

.cis-card_wrap.colored_shade_3.card_strip .row .card .card-icon {
  background: transparent;
  width: auto;
  height: auto;
  padding: 0;
  display: inline-block;
}
[dir=ltr] .cis-card_wrap.colored_shade_3.card_strip .row .card .card-icon { 
  left: 8px;
  top: 8px;
  right: auto;
  bottom: auto;
}
[dir=rtl] .cis-card_wrap.colored_shade_3.card_strip .row .card .card-icon { 
  right: 8px;
  top: 8px;
  left: auto;
  bottom: auto;
}
[dir=rtl] .cis-card_wrap.colored_shade_3.card_strip .row > div > .card .card-body {
  padding: 7px 50px 7px 15px;
}


.cis-card_wrap.colored_shade_3.card_strip .row .card .card-icon img {
  width: 30px;
  height: 30px;
}

.cis-card_wrap.colored_shade_3.card_strip .row .card {
  margin-bottom: 15px !important;
}
.cis-card_wrap.colored_shade_3.card_strip .row > div:last-child > .card {
  margin-bottom: 0px !important;
}


.cis-card_wrap.colored_shade_4.card_strip .row > div > .card .card-body {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 7px 15px 7px 50px;
}

.cis-card_wrap.colored_shade_4.card_strip .row > div > .card  .text-truncate {
  min-height: auto;
}

.cis-card_wrap.colored_shade_4.card_strip .card .ff-secondary.mb-4 {
  margin-bottom: 0 !important;
}
.cis-card_wrap.colored_shade_4.card_strip .row .card .card-icon {

  background: transparent;
  width: auto;
  height: auto;
  padding: 0;
  display: inline-block;
}
[dir=ltr] .cis-card_wrap.colored_shade_4.card_strip .row .card .card-icon {
  left: 8px;
  top: 8px;
  right: auto;
  bottom: auto;
}
[dir=rtl] .cis-card_wrap.colored_shade_4.card_strip .row .card .card-icon { 
  right: 8px;
  top: 8px;
  left: auto;
  bottom: auto;
}
[dir=rtl] .cis-card_wrap.colored_shade_4.card_strip .row > div > .card .card-body {
  padding: 7px 50px 7px 15px;
}

.cis-card_wrap.colored_shade_4.card_strip .row .card .card-icon img {
  width: 30px;
  height: 30px;
}

.cis-card_wrap.colored_shade_4.card_strip .row .card {
  margin-bottom: 15px !important;
}
.cis-card_wrap.colored_shade_4.card_strip .row > div:last-child > .card {
  margin-bottom: 0px !important;
}

.profile-input {
  display: flex;
}

.custom-form-check {
  width: 30px;
  display: flex;
  justify-content: center;
}